import Image from "next/image"
import styles from "./LocationTag.module.scss"

const LocationTag = ({ location, style }) => {
	return (
		<span className={`${styles.locationTag} locationTag`} style={style}>
			<span className={`${styles.locationIcon}`}>
				<Image
					src="/assets/img/icons/map-pin.svg"
					alt="Location icon"
					width="15"
					height="15"
				/>
			</span>
			<span className={`${styles.countryName}`}>{location}</span>
		</span>
	)
}

export default LocationTag
