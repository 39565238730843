import LinkCard from "components/card/LinkCard";
import LocationTag from "components/ui/Tag/LocationTag";

const DestinationLinkCard = ({
  destination,
  cardProps,
  locationAsTag = false,
  locationSubtitle = true,
  cardData = true,
  containerStyles,
}) => {
  const cardRegion = destination?.destination_details?.region?.region ? (
    <LocationTag
      location={destination.destination_details.region.region
        .split("-")
        .map((word) => word[0].toUpperCase() + word.slice(1, word.length))
        .join(" ")}
    />
  ) : (
    ""
  );

  return (
    <LinkCard
      title={destination.destination_title}
      slug={`/destinations/${
        encodeURIComponent(destination?.destination_details?.region?.region) ??
        encodeURIComponent(destination?.region?.region)
      }/${encodeURIComponent(destination.slug)}`}
      image={
        destination?.images?.small ||
        destination?.image ||
        destination?.seo?.metaMedia.url ||
        destination?.search_engine_optimization?.metaMedia?.url
      }
      data1={
        cardData ? (!locationAsTag && locationSubtitle ? cardRegion : "") : ""
      }
      tags={
        cardData
          ? [
              locationAsTag
                ? destination.destination_details.region.regionName
                : "",
            ]
          : ""
      }
      titleHeight={2}
      containerStyles={containerStyles}
      {...cardProps}
    />
  );
};

export default DestinationLinkCard;
